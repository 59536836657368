const AlbumNotes = {
    bigBang: `What was there before existence itself?

    No matter. No energy. Not even the absence of those things. What could be absent from Nothing? The most one could say about Nothing is that it held potential. A capacity for what might come.
        
    And somehow, within that incomprehensible potential, Nothing gave birth to EVERYTHING.
    
    Energy. Matter. Time. Everything that would ever exist. A singularity, infinitely hot and dense, where even the fundamental forces of the universe would be indistinguishable from each other, if only for a brief moment.
        
    —
        
    Scientists from a small blue planet would one day discover a set of fundamental constraints in their universe. Planck units, as they would be called, are limits of measurement dictated by quantum physics.
    
    The smallest possible measurement of time, a “Planck time”, is about 10^-43 seconds.
        
    Just after the moment of the singularity, but before the universe would reach 1 Planck time in age, their scientists could only speculate what it might have been like.
        
    At that time, the four fundamental forces were unified. The conditions were simply too hot and too dense for them to be distinguishable from one another. But as the universe expanded and cooled within that first Planck time of its existence, the gravitational force would break free.
        
     —
        
    The universe existed in that state for a long time, expanding and cooling for another 10 million Planck times, or roughly 10^-36 seconds. Then, the strong nuclear force, which would eventually be responsible for keeping atomic nuclei together, followed gravity's lead, and separated itself from the others.
    
    During this time, the entire universe was the size of a proton, so no nucleus could have actually formed. Nevertheless, when the strong nuclear force broke away, it would trigger a phase of expansion called inflation. In a billionth of a billionth of a billionth of a second, the universe would grow to the size of a softball. Faster than the speed of light.
        
    —
        
    The universe continued to expand this way for a long time, until it reached around one trillionth of a second old. Then, the temperature dropped to a cool quadrillion degrees, and the weak nuclear force and the electromagnetic force would finally be able to break free from each other.
    
    —
    
    After one millionth of a second, the first protons and neutrons would begin to combine into Hydrogen nuclei. 
    
    After 3 minutes, Helium and Lithium nuclei would follow.
    
    It would take over 300,000 years before the universe would be cold enough for nuclei to capture electrons and form proper atoms.
    
    And it would take at least another 30 million years of gravitational attraction to begin fusing these atoms into the first stars.
    `,
    cmb: `The universe left evidence of its birth. For about 300,000 years, it existed as superheated plasma. Completely opaque. After a long time of expansion and cooling, the plasma coalesced into atoms, and the universe suddenly became transparent.

    Nearly 14 billion years later, scientists from the blue planet would be able to observe the energy released at that moment of transparency.
    
    An afterglow of primordial light. A projection from the hot early universe, permeating, spreading outward and inward in every direction, and red-shifted into microwaves at the edge of the observable universe. 
    
    Light created even before the first stars would be born. Light that would expand and slowly cool forever.`,
    nebulae: `The early universe produced massive clouds of primordial hydrogen and helium gas.

    Tiny variations in these clouds allowed hydrogen atoms to form molecular hydrogen, which in turn would cool the gas, allowing gravity to begin a long transformation.
    
    Enough mass in one place caused hydrogen nuclei to collapse in on themselves and start a runaway fusion reaction, giving birth to a star.
    
    The first nebulae were hot and dense, and the stars they produced were massive and bright.
    
    The next generation of stars would be born from nebulae containing heavier elements, metals, remnants from supernovae explosions of those first bright stars. Offspring that would themselves explode and provide more metals to seed more nebulae and produce even newer stars.
    
    —
    
    This process would eventually create a yellow sun in an arm of a large spiral galaxy. From the perspective of the blue planet orbiting the sun, the night sky appeared to be splashed in milky white.
    
    Eventually, they would uncover more truths about their universe. The white milky sky was caused by the abundance of stars in the plane of their galaxy and their perspective from within it.`,
    abiogenesis: `Their galaxy contained hundreds of billions of stars, many of which looked like their own sun, circled by one or more rocky planets whose orbits fell within the so-called "habitable zone".

    Their scientists would one day estimate that the Milky Way galaxy could contain as many as 6 billion such worlds. So what made theirs so different? Why did theirs produce life?
    
    —
    
    It was an admittedly myopic perspective, but the spontaneous creation of life, as they would come to understand it, needed many things:
    
    A surface with large bodies of water.
    
    A diversity of elements and complex molecules. 
    
    A magnetic field to let in certain types of energy from their sun while limiting its more harmful radiation and particles.
    
    An abundance of energy gradients; tide pools, volcanically heated salinated and fresh water. Energy for those chemicals to combine in interesting ways.
    
    And none of the scientists would ever know for sure, but probably a bit of chance. Whatever was actually required, their planet provided the necessary conditions.
    
    —
    
    The precursors of life were molecules that self-replicated and self-assembled.
    
    The molecules formed simple structures at first, but quickly grew in complexity. One type of molecule in particular was responsible for encoding how other molecules could be created and assembled.
    
    All life on their planet descended from one microscopic single-celled organism. And billions of years of environmental hardship and competition for resources would lead to a vibrant explosion of biodiversity.
    
    Life would find a way to exist and thrive in every nook and cranny on their planet.`,
    canopies: `Much of the species' early history would be spent on their planet’s rocky surface. It was a combination of things that transformed their world, their home, into something almost totally uninhabitable. And it was a true achievement to leave before they could annihilate themselves.`,
    sapience: `LYRICS:
    
    our minds elide the time, the flight of light, from distant galaxies
    the red-shifted wash arrives, a glaring sign of our reality
        
    we pushed beyond into the void
    we fled the paradise that we left destroyed
        
    we sang to the galaxy, radiating light
    that we might hear a voice, that we might share our plight
    but the void echoed back humanity’s own chorus
    and the stars shifted red and Nothing was for us
        
    we looked to the end of it all, and we found Nothing
    we looked to the end of it all, and we found Nothing
    we looked to the end of it all, and we are Nothing
    we looked to the end of it all, and we are Nothing
    we are Nothing
    we are Nothing
    we are Nothing
    we are Nothing
    —
        
    After a time of suffering and hardship, they built ships and left, taking as many people and resources as they could launch out of their planet's massive gravity well.
        
    They settled throughout their solar system's planets and moons at first. Then they set their eyes on habitable worlds in nearby star systems.
        
    On an individual's scale their expansion was painfully slow. They never discovered a way around faster than light travel, another of the universe's unbreakable constraints.
        
    Multi-generational ships traveled to the closest star systems. The people who launched in those ships understood they wouldn't live long enough to arrive at their destination. Neither would their children or grandchildren. But the ships would arrive in a dozen or so generations, and their descendants would be the first to walk on a truly alien world.
        
    Some made these foreign systems their home. Others, born into a culture of traveling between the stars, could only continue onward, the species slowly creeping through the arm of their spiral galaxy.
        
    Wherever they went, they settled or took what they needed. The raw material for their existence and expansion was abundant.
        
    —
        
    Isolated by unfathomable distances, they evolved and adapted. They made great discoveries. They even found simple lifeforms in many places. 
        
    But they would never find another sapient mind, nor would they ever discover a reason for their own existence. And no matter how far they traveled from their blue planet, they were still trapped in their own galaxy. The distances were simply too great to push through intergalactic space, their final unbroken canopy.
        
    Millenia passed. A billion millennia would pass before the species and their descendants would eventually die off, starved of energy and resources.
        
    They had predicted this fate. Spacetime expanded from the moment of the big bang. The galaxies were observed accelerated away from each other. The light from their stars shifted red and that was the proof.
    
    The stars would eventually burn out. Time would keep marching. Even if they survived trillions of years, it would merely be a blip. All of their species, all life that may have emerged in other parts of the universe, all of it would pale in comparison to the cold darkness at the end of time.
        
    Life was a transient peak. On a timeline with no end, it wouldn't even register.`,
    relentlessMarch: `One Planck time is roughly 10^-43 seconds. For a species born billions of years later, it is incomprehensibly short.

    And yet, it could be said that the most interesting thing to ever happen in the universe, did so within that tiny duration. As gravity is the curvature of spacetime, it's difficult to know if time itself even behaved the same, before gravity existed as a distinct force.
    
    What is known is that within the first Planck time, everything that would ever exist and everything that would ever happen in the universe was set in motion.
    
    —
    
    From the moment of the big bang, the universe began expanding. The stars will eventually burn through their fuel, and the night sky will become dark and cold. The expansion will last forever.
    
    Black holes will remain for a long time. A timeline too massive to comprehend for a species born into such a young universe. But even the black holes will radiate away, evaporating into nothing. It will be 10^100 years or more for the largest ones.
    
    By this time, the universe will be cold and dark, and for 10^1000 more years nothing will happen. But the atoms of black dwarfs, the last cold remnants of the stars that didn’t become supernovae, will slowly fuse due to quantum effects. Eventually these black dwarfs will fuse enough of their atoms to collapse in on themselves and explode. The least dense of these stars will take up to 10^10000 years.
    
    The explosions of the black dwarfs will be the last thing that ever happens in the universe, but time won’t stop there. Time will wring out any remaining heat gradient. The universe will exist as a cold, mostly empty void, lightly sprinkled with dust and rocks.
    
    —
    
    A Planck time is dwarfed by the time it took for the universe to produce the 4 distinct forces that govern its interactions.
    
    Which itself is dwarfed by the time to cool and expand and form the first atoms.
    By the time for nebulae to give birth to the first stars.
    For life to emerge.
    For intelligent species to expand to the edges of their galaxies. 
    For the stars to die. 
    For all life to end. 
    For the black holes to evaporate. 
    For the black dwarfs to explode.
    For no heat to remain in the universe at all.
    
    But all of it is dwarfed by the time to come. The time of cold darkness. A time so long that, by comparison, even these incomprehensible durations are reduced to nothing.`,
    redShift: `Scientists from the blue planet could see it coming. Even before they left for other stars.

    They had evolved to see a spectrum of electromagnetic radiation, so-called “visible light”. At the high-energy end of the spectrum, light had a shorter wavelength and they called it “blue”. At the low-energy end, the wavelength was longer and they called it “red”
        
    A curious property of waves is that their perceived energy is affected by the relative velocity between the source of the wave and the observer.
        
    When the source and the observer are moving closer together, from the observer's perspective, the wavelengths are more energetic. The waves crash into the observer at a faster rate, which makes light appear more blue than what was actually emitted from the source. They called this phenomenon a "blue shift".
    
    When source and observer move away from each other, the opposite happens. The observer sees less energetic light. The waves crash less frequently, and light appears to be more red, and it follows that they called this a "red shift".
        
    —
        
    Their scientists observed that light from certain galaxies was more red than others. In fact, the greater the distance to the observed galaxy, the redder its light.
        
    The only explanation was that the galaxies were moving away from one another. Not only moving away, but *accelerating* away from one another. Space itself was expanding in the empty void between the stars, and stretching light along with it.`,
    collapsingStar: `The brightest stars in the universe live the shortest lives, and die in the most spectacular way.

    Their massive gravity wells ignite a fusion reaction that burns through their fuel, hydrogen at first, but then heavier and heavier elements. Until they contain a core of too much iron, the first element whose fusion energy is too large to keep the sun's furnace going. 
    
    A star’s fusion reaction is at odds with the force of gravity. The massive energy output creates an extreme pressure that prevents the sun from completely collapsing under its own weight. But the sudden loss of the fusion reaction is the final losing battle. The core collapses and then explodes, leaving behind a neutron star or a black hole, and sometimes releasing as much light as an entire galaxy. The violence of this act is nearly unmatched by anything in the universe. 
    
    It's the fate of all large stars, but also part of a larger cycle. The supernova fuses heavier elements and sends its matter into nebulae as seeds for new stars.`,
    oneLastAlarm: `Some of the scientists from the blue planet were unsatisfied by the red shifted galaxies and the conclusion it led to. 

    Not that they didn't accept that the universe was expanding. That was an incontrovertible fact. 
    
    But what were the consequences of expanding space? Space exists everywhere, not just between galaxies. 
    
    The galaxies were being pushed apart, but could the expansion of space overcome the gravitational attraction of stars within a galaxy…?
    
    Some of them saw the end rapidly approaching. They didn't know when it would happen, only that it would.
    
    Some of them tried to prepare.
    
    Some of them thought it was preferable, compared to the cold dark infinite alternative.
    
    And some of them sounded the alarm, even though there was nothing they could do about it.`,
    spacetime: `First, the galaxies began disappearing. Space expanded and the light from other galaxies began to be outpaced, pushing them outside their limited bubble of visible universe. Local starlight, produced by stars within their own galaxy, was all that existed for a long time.

    No one lived on the blue planet anymore, but their descendant's eyes watched from around the Milky Way. From one vantage point, near the galactic center, stars at the galaxy’s edge turned deep red. Slowly at first, and then the red crept toward them. 
    
    More and more stars accelerated outward as the space between them expanded. Stars at the edge began disappearing as they too left the visible universe. This happened long before their eyes could detect that the light wasn’t reaching them anymore.
    
    After a short time, only local gravity could overcome expansion. A pair of eyes on a planet orbiting its star could only see the star system itself.
    
    But space kept expanding. In an instant, the furthest planets disappeared. The star disappeared. The air became vacuum. And the bonds holding molecules together were overcome and split by that ever expanding space. Any life that could have existed in the universe was undone.
    
    And while that was the last moment any living thing could perceive what was happening, it was not where it ended.
    
    Space expanded and pushed apart the nuclei of every atom in the universe. 
    
    It stretched waves of light into infinite flat lines.
    
    And it pushed apart time itself. 
    
    And just as they couldn't peer into that first Planck time of the universe, they couldn't have peered beyond the end of time either.`,
    whatHappensNow: `The species from the blue planet faced all manner of incomprehensible things.

    A nothingness that spontaneously created everything.
    
    A time so short and so close to creation that they couldn't be sure if time actually existed within it.
    
    A potential endless future.
    
    —
    
    Despite the loneliness and indifference in all they discovered, their curiosity never waned. They still asked the questions they knew were impossible to answer. They still tried so hard to understand what was beyond the limits of their perception.
    
    Maybe the end was the start of a new beginning. A new big bang and a new universe.
    
    Maybe a fifth fundamental force separated itself and the universe would inflate again, settling into a new type of equilibrium for a while. Maybe life could find a way to exist there.
    
    Maybe it was a simulation that served its purpose or reached its computational resource limit.
    
    Maybe the universe existed in some higher dimensional place. But what contained that place, and what contained *that* place, and so on?
    
    Maybe some deity was testing them. But why? And why were they so distant from their creation?
    
    Maybe
    
    Maybe
    
    Maybe
    
    —
    
    Maybe it was a million things they could never understand.
    
    Maybe the beginning was just the beginning, and the end was just the end, and there was no more importance placed on it. 
    
    A chance happening. A fluke.
    
    Maybe it was enough for some of them. A happenstance to be questioned and cherished all at once.
        
    Maybe they could live their short lives, faced with the existential loneliness of it all, and still believe the fluke was beautiful. That it was everything.`,
}

export default AlbumNotes;