const nasaImagesByCat = {
  blackHole: [
    "PIA10221",
    "PIA16114",
    "PIA19339",
    "PIA08696",
    "PIA22355",
    "PIA18919",
    "ARC-1985-AC85-0199-5",
    "PIA12966",
    "ACD17-0168-12",
    "PIA20912",
    "PIA15414",
    "PIA01884",
    "PIA10093",
    "PIA14730",
    "PIA17562",
    "PIA20051",
  ],
  bigBang: [
    "GSFC_20171208_Archive_e000033",
    "PIA25165",
    "0203045",
    "9601396",
    "GSFC_20171208_Archive_e001901",
    "hubble-spies-big-bang-frontiers_22202541749_o",
    "PIA25164",
    "GSFC_20171208_Archive_e000403",
    "PIA20054",
    "GSFC_20171208_Archive_e000402",
    "PIA15251",
    "GSFC_20171208_Archive_e000281",
    "hubble-uncovers-a-mysterious-hermit_27482883442_o",
    "GSFC_20171208_Archive_e000528",
    "PIA25163",
    "GSFC_20171208_Archive_e000125",
    "PIA17568",
    "GSFC_20171208_Archive_e001002",
    "PIA22079",
  ],
  cmb: ["PIA16873", "PIA16877", "GSFC_20171208_Archive_e000125", "PIA16880"],
  earthFromSpace: [
    "41G-120-082",
    "S02-12-833",
    "as4-01-750",
    "s73-35078",
    "STS047-20-015",
    "s65-63282",
    "STS059-58-018",
    "s65-45717",
    "s65-63162",
    "s65-63826",
    "s73-35080",
    "as13-60-8588",
    "s65-63780",
  ],
  emptySky: [
    "GSFC_20171208_Archive_e001569",
    "GSFC_20171208_Archive_e000999",
    "GSFC_20171208_Archive_e001646",
  ],
  eventHorizon: [
    "GSFC_20171208_Archive_e001100",
    "GSFC_20171208_Archive_e000386",
    "GSFC_20171208_Archive_e001101",
    "iss023e057948",
    "iss023e032397",
    "GSFC_20171208_Archive_e000984",
    "GSFC_20171208_Archive_e001102",
    "GSFC_20171208_Archive_e001099",
    "GSFC_20171208_Archive_e001098",
  ],
  exoplanet: [
    "PIA23690",
    "PIA23689",
    "PIA22069",
    "PIA20690",
    "PIA22087",
    "PIA22098",
    "GSFC_20171208_Archive_e002172",
  ],
  fire: [
    "GSFC_20171208_Archive_e000024",
    "GSFC_20171208_Archive_e000271",
    "GSFC_20171208_Archive_e000425",
    "GSFC_20171208_Archive_e000126",
    "PIA11088",
    "PIA20734",
    "GSFC_20171208_Archive_e001400",
    "PIA22046",
    "GSFC_20171208_Archive_e001383",
    "GSFC_20171208_Archive_e001423",
    "GSFC_20171208_Archive_e001092",
    "GSFC_20171208_Archive_e000674",
    "PIA02158",
    "PIA03856",
  ],
  flood: [
    "PIA14137",
    "PIA11989",
    "PIA14972",
    "PIA26112",
    "PIA20533",
    "PIA16945",
    "PIA13775",
    "PIA01806",
  ],
  forest: [
    "PIA21826",
    "PIA22034",
    "PIA15150",
    "PIA18643",
    "sts111-367-014",
    "PIA01755",
    "sts046-75-79a",
    "ED04-0056-054",
    "s39-83-059",
    "ED04-0056-046",
    "ED04-0056-057",
    "ED04-0056-061",
    "ED04-0056-055",
    "s49-91-079",
    "PIA11218",
    "sts059-s-068",
    "PIA23678",
    "ED04-0056-043",
    "sts059-s-039",
    "PIA02158",
    "ED04-0056-048",
    "sts060-87-087",
    "PIA01859",
    "PIA24950",
    "PIA11165",
    "PIA19738",
  ],
  galaxy: [
    "PIA09579",
    "PIA07904",
    "PIA09653",
    "PIA09106",
    "PIA04624",
    "GSFC_20171208_Archive_e000017",
    "GSFC_20171208_Archive_e001172",
    "PIA07828",
    "PIA04921",
    "GSFC_20171208_Archive_e000499",
    "GSFC_20171208_Archive_e000033",
    "PIA04217",
    "PIA04230",
    "GSFC_20171208_Archive_e000687",
    "GSFC_20171208_Archive_e000022",
    "PIA11998",
    "PIA04209",
  ],
  globalWarming: [
    "sts059-s-027",
    "PIA17312",
    "iss036e028913",
    "PIA23514",
    "0300805",
    "PIA26100",
    "PIA01737",
    "GSFC_20171208_Archive_e000638",
    "PIA02300",
    "PIA02670",
  ],
  hurricane: [
    "GSFC_20171208_Archive_e001628",
    "KSC-20161008-PH-CSH01_0177",
    "iss057e023121",
    "GSFC_20171208_Archive_e000520",
    "41G-121-099",
    "0401560",
    "0401557",
    "0401559",
    "GSFC_20171208_Archive_e000597",
  ],
  meltingIcecaps: [
    "PIA17282",
    "GSFC_20171208_Archive_e000099",
    "PIA14014",
    "GSFC_20171208_Archive_e001453",
    "GSFC_20171208_Archive_e000638",
    "PIA24983",
    "PIA24162",
    "GSFC_20171208_Archive_e000213",
    "PIA17281",
    "iss059e034612",
  ],
  naturalDisaster: [
    "sts068-s-052",
    "GSFC_20171208_Archive_e001884",
    "GSFC_20171208_Archive_e001532",
    "PIA03899",
  ],
  nature: [
    "KSC-20170130-PH_SWW01_0001",
    "KSC-20201028-PH-JBS02_0013",
    "KSC-20161213-PH_SWW01_0010",
    "KSC-2011-8340",
    "KSC-20201028-PH-JBS02_0109",
    "KSC-2011-8335",
  ],
  nebula: [
    "PIA04215",
    "PIA14038",
    "GSFC_20171208_Archive_e001743",
    "hubble-sees-the-wings-of-a-butterfly-the-twin-jet-nebula_20283986193_o",
    "PIA14417",
    "PIA13118",
    "GSFC_20171208_Archive_e000134",
    "GSFC_20171208_Archive_e000273",
    "PIA13028",
    "PIA14728",
    "PIA20357",
    "GSFC_20171208_Archive_e000053",
    "PIA04227",
    "GSFC_20171208_Archive_e000054",
    "PIA24579",
    "PIA07343",
    "GSFC_20171208_Archive_e002020",
    "0203048",
    "hubble-finds-a-little-gem_20185002499_o",
    "GSFC_20171208_Archive_e002076",
    "GSFC_20171208_Archive_e000699",
    "PIA04249",
    "PIA25433",
    "PIA14444",
    "PIA04200",
    "PIA13108",
    "PIA15413",
    "PIA23646",
    "PIA13014",
    "0300724",
    "PIA03678",
    "PIA23409",
    "PIA04226",
    "PIA03515",
    "GSFC_20171208_Archive_e002159",
    "hubble-view-of-a-nitrogen-rich-nebula_19178136615_o",
    "GSFC_20171208_Archive_e000195",
    "PIA15635",
    "GSFC_20171208_Archive_e001465",
    "PIA15415",
    "PIA10955",
    "GSFC_20171208_Archive_e002075",
    "PIA13451",
    "PIA09108",
    "GSFC_20171208_Archive_e002152",
    "PIA14104",
    "PIA09178",
    "carina_nebula",
    "PIA18249",
    "0302063",
    "PIA09268",
    "PIA05062",
    "GSFC_20171208_Archive_e001915",
    "PIA21474",
    "GSFC_20171208_Archive_e000148",
    "GSFC_20171208_Archive_e001955",
    "PIA13127",
    "PIA14092",
    "PIA09962",
    "PIA13453",
    "PIA09267",
    "PIA07902",
    "PIA14442",
    "PIA15817",
    "PIA04216",
    "GSFC_20171208_Archive_e002086",
    "PIA23867",
    "PIA17563",
    "PIA13111",
    "PIA09266",
    "PIA14415",
    "7890338",
    "PIA25434",
    "GSFC_20171208_Archive_e002155",
    "PIA03606",
    "PIA13110",
    "GSFC_20171208_Archive_e001464",
    "PIA14731",
    "PIA11968",
    "PIA03294",
    "GSFC_20171208_Archive_e001518",
    "PIA17834",
  ],
  planets: [
    "PIA23131",
    "PIA23302",
    "PIA19377",
    "PIA19369",
    "PIA18161",
    "PIA21752",
    "PIA18166",
    "PIA07096",
    "PIA21475",
    "PIA19682",
    "PIA22085",
    "PIA22082",
    "PIA23684",
    "PIA21943",
    "PIA21470",
    "PIA22962",
    "PIA20913",
    "PIA23002",
    "PIA23172",
    "PIA22745",
    "PIA22482",
  ],
  planktonBloom: [
    "sl4-137-3721",
    "GSFC_20171208_Archive_e001950",
    "iss036e035635",
    "GSFC_20171208_Archive_e000969",
    "sts067-722a-053",
    "PIA25069",
    "GSFC_20171208_Archive_e000827",
    "sts060-88-070",
    "GSFC_20171208_Archive_e000716",
  ],
  pollution: [
    "PIA23339",
    "0102016",
    "sts046-74-094",
    "GSFC_20171208_Archive_e000518",
    "sts082-723-071",
    "as07-11-2022",
    "GSFC_20171208_Archive_e001269",
    "iss069e053745",
    "sts073-723-054",
    "iss011e12401",
  ],
  redshiftGalaxies: [
    "PIA12485",
    "PIA15256",
    "PIA14401",
    "PIA07396",
    "PIA13102",
    "PIA15910",
    "PIA13900",
    "PIA17011",
    "PIA17565",
    "PIA20052",
    "PIA14723",
    "PIA12834",
    "PIA25163",
    "PIA12467",
    "GSFC_20171208_Archive_e001925",
    "PIA14885",
    "PIA02917",
    "PIA07899",
    "PIA13789",
    "PIA14721",
    "PIA03031",
    "PIA10968",
    "PIA18847",
  ],
  rocketLaunch: [
    "201304210013HQ",
    "201108050004HQ",
    "DSC07878",
    "KSC-20230507-PH-RLS01_0001-reupload",
    "KSC-20230507-PH-RLS01_0005-reupload",
    "201108050006HQ",
    "201108050005HQ",
    "GSFC_20171208_Archive_e000809",
    "MAF_20220830__KSC_Artemis1_epb_017",
    "GSFC_20171208_Archive_e000703",
    "201304210006HQ",
    "B1B_Crew_Night_Launch",
    "GSFC_20171208_Archive_e000127",
    "DSC07877",
    "MAF_20220830__KSC_Artemis1_epb_010",
  ],
  sun: [
    "GSFC_20171208_Archive_e001510",
    "GSFC_20171208_Archive_e001661",
    "GSFC_20171208_Archive_e000762",
    "PIA22197",
    "PIA22673",
    "GSFC_20171208_Archive_e001517",
    "GSFC_20171208_Archive_e001662",
    "PIA25628",
    "GSFC_20171208_Archive_e001435",
    "GSFC_20171208_Archive_e002035",
    "GSFC_20171208_Archive_e000759",
    "GSFC_20171208_Archive_e001516",
    "GSFC_20171208_Archive_e001434",
    "GSFC_20171208_Archive_e001556",
    "GSFC_20171208_Archive_e000808",
    "GSFC_20171208_Archive_e001549",
    "GSFC_20171208_Archive_e001504",
    "GSFC_20171208_Archive_e001553",
  ],
  supermassive: [
    "GSFC_20171208_Archive_e000500",
    "PIA12348",
    "PIA20865",
    "PIA20912",
    "PIA20027",
    "0301628",
    "GSFC_20171208_Archive_e001893",
    "GSFC_20171208_Archive_e002148",
    "GSFC_20171208_Archive_e000001",
    "PIA21087",
    "PIA20057",
    "0202374",
    "GSFC_20171208_Archive_e000719",
    "PIA15806",
    "PIA18919",
    "GSFC_20171208_Archive_e001292",
  ],
  supernova: [
    "PIA06907",
    "0400393",
    "PIA08533",
    "PIA03519",
    "GSFC_20171208_Archive_e001986",
    "9905485",
    "PIA11435",
    "PIA09071",
    "PIA18845",
    "0301839",
    "PIA17838",
    "GSFC_20171208_Archive_e002183",
  ],
};

export default nasaImagesByCat;
